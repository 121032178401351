import axios from 'axios'
import { utils } from '@/utils'
import { getToken } from '@/utils/auth'
import store from '../store'
import Vue from 'vue'
let token = getToken()

const config = {
  baseURL: utils.getApiUrl(),
  timeout: 60000,
}

export const _axios = axios.create(config)

_axios.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.code === 401) {
      Vue.$loginBox.show()
      store.dispatch('LogOut')
    } else if (res.code === 403) {
      // 403状态码, token验证错误
      Vue.$loginBox.show()
      store.dispatch('LogOut')
    } else if (res.code === 402) {
      // 402状态码, token过期
      Vue.$loginBox.show()
      store.dispatch('LogOut')
    } else {
      return response
    }
  },
  (error) => {
    console.error(error.message)
    if (error.message.indexOf('timeout') != -1) {
      return { data: { code: 2, message: '请求超时' } }
    }
  },
)

if (token) {
  _axios.defaults.headers['Authorization'] = token
}

// _axios.interceptors.request.use(
//   cfg => cfg,
//   error => Promise.reject(error),
// )

// Plugin.install = function(Vue) {
//   Vue.axios = _axios;
//   window.axios = _axios;
//   Object.defineProperties(Vue.prototype, {
//     axios: {
//       get() {
//         return _axios;
//       },
//     },
//     $axios: {
//       get() {
//         return _axios;
//       },
//     },
//   });
// };

// Vue.use(Plugin)

// export default Plugin;
