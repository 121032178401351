/* eslint-disable */

class Utils {
  /*
   * 编写公共方法的类，可查看ES6语法
   * */
  static timeout = null

  constructor() { }

  getApiUrl() {
    return process.env.VUE_APP_API_URL
  }

  getWebUrl() {
    return process.env.VUE_APP_WEB_URL
  }

  getStaticUrl() {
    return process.env.VUE_APP_STATIC_URL
  }

  formatToYMDHMS(rawDate) {
    if (!rawDate) return ''
    // 格式化时间为 YYYY-MM-DD HH:MM:SS
    const date = new Date(rawDate)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hour = date.getHours().toString().padStart(2, '0')
    const min = date.getMinutes().toString().padStart(2, '0')
    const sec = date.getSeconds().toString().padStart(2, '0')
    return `${year}-${month}-${day} ${hour}:${min}:${sec}`
  }

  // 获取年月日
  getYMD(date) {
    date = date ? new Date(date) : new Date()
    let y = date.getFullYear()
    let m = (date.getMonth() + 1).toString().padStart(2, '0')
    let d = date.getDate().toString().padStart(2, '0')
    return `${y}-${m}-${d}`
  }

  getUrlKey(name) {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
          location.href,
        ) || [, ''])[1].replace(/\+/g, '%20'),
      ) || null
    )
  }

  imgUrl(cover, type = 1) {



    let coverUrlMap = {
      1: {
        disney: 'disney-f.svg',
        youtube: 'youtube-f.svg',
        spotify: 'spotify-f.svg',
        netflix: 'netflix-f.svg',
        duolinguo: 'duolinguo-f.png',
      },
      2: {
        disney: 'disney-0.svg',
        youtube: 'youtube-0.svg',
        spotify: 'spotify-0.svg',
        netflix: 'netflix-0.svg',
        duolinguo: 'duolinguo-0.png',
      }
    }

    let coverUrl = coverUrlMap[type][cover]

    return require(`@/assets/${coverUrl}`)
  }

}

const utils = new Utils()

export { utils }
