import Vue from 'vue'
import Login from './Index.vue'

const LoginConstructor = Vue.extend(Login)

const instance = new LoginConstructor({
  el: document.createElement('div')
})

instance.show = false
instance.redirectTo = ''

const loginBox = {
  show() {
    instance.show = true
    document.body.appendChild(instance.$el)
  },
  hide() {
    instance.show = false
  },
  redirectTo(url) {
    instance.redirectTo = url
  }
}

export default {
  install() {
    if (!Vue.$loginBox) {
      Vue.$loginBox = loginBox
    }
    Vue.mixin({
      created() {
        this.$loginBox = Vue.$loginBox
      }
    })
  }
}