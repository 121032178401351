import { _axios } from '@/utils/axios'

export function getUserInfo(params) {
  return _axios({
    url: '/user/info',
    method: 'get',
    params
  })
}

export function wechatAuth(params) {
  return _axios({
    url: '/user/wechat/auth',
    method: 'get',
    params
  })
}

export function login(data) {
  return _axios({
    url: '/user/login',
    method: 'post',
    data
  })
}

export function getPhoneCode(params) {
  return _axios({
    url: '/user/phone/code',
    method: 'get',
    params
  })
}
