/* eslint-disable */
import { getUserInfo, login } from '@/api/user'
import { removeToken, setToken } from '@/utils/auth'
// import { reportError } from '@/utils/log'
import router from '@/router'

const user = {
  state: {
    user: {}
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user
    },
  },
  actions: {
    // 登录
    // eslint-disable-next-line
    Login({}, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((res) => {
            if (res.data.code == 0) {
              setToken(res.data.token)
            }
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取用户信息
    async GetInfo({ commit }) {
      try {
        let res = await getUserInfo()
        if (res.data.code === 0) {
          commit('SET_USER', res.data.user)
        } else {
          removeToken()
        }
      } catch (error) {
        // reportError(error)
      }
    },
    // 登出
    LogOut() {
      removeToken()
      router.push('/home')
    },
  },
}

export default user
