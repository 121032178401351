import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { getToken, setToken,removeToken } from '@/utils/auth'
import { wechatAuth } from '@/api/user'
import { Toast } from 'vant'
import Loading from 'vuejs-loading-plugin'

import Login from '@/components/login'
// 注册为全局组件
Vue.use(Login)


import { utils } from '@/utils'

Vue.use(Loading, {
  dark: true, // default false
  text: '加载中', // default 'Loading'
  background: 'rgb(0,0,0,0.7)', // set custom background
})

Vue.config.productionTip = false

const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0]
  let htmlWidth =
    document.documentElement.clientWidth || document.body.clientWidth
  if (htmlWidth >= 750) {
    htmlWidth = 750
  }
  if (htmlWidth <= 320) {
    htmlWidth = 320
  }
  htmlDom.style.fontSize = `${htmlWidth / 7.5}px`
}
window.onresize = setHtmlFontSize
setHtmlFontSize()

let ua = navigator.userAgent.toLowerCase()

const isWeixin = /micromessenger/i.test(ua) || /windows phone/i.test(ua)

const isPC = !navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
)

// Vue.prototype.$reportError = reportError
Vue.prototype.$isPC = isPC
Vue.prototype.$isWeixin = isWeixin
Vue.prototype.$toast = Toast

const postWechatAuth = async (code) => {
  let res = await wechatAuth({
    code,
  })
  if (res.data.code == 0) {

    if (res.data.token) {
      setToken(res.data.token)
      Vue.prototype.$token = res.data.token
      store.dispatch('GetInfo')
    } else {
      removeToken()
      store.commit('SET_USER', {
        openId: res.data.openId
      })
    }
  } else {
    window.location.href = 'https://pinka.yyouren.com' // code有可能会无效，需要重新进入重新获取
  }
}

let cf = utils.getUrlKey('cf') || document.referrer
if (cf) {
  localStorage.setItem('cf', cf)
}

let ic = utils.getUrlKey('ic')
if (ic) {
  localStorage.setItem('ic', ic)
}

if (isWeixin) {
  // 如果是微信用户，则凭借 openId 用来识别用户

  let code = utils.getUrlKey('code')

  if (!code) {
    // 获取code
    let appid = 'wx737bd4eb9fa1266c'
    let redirectUrl = window.location.href
    redirectUrl = encodeURI(redirectUrl)
    let authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base#wechat_redirect`
    window.location.href = authUrl
  } else {
    postWechatAuth(code)
  }
}


const whiteList = ['/login', '/home', '/service-agreement', '/privacy-agreements', '/user-agreements']

router.beforeEach((to, from, next) => {

  if (getToken()) {
    Vue.prototype.$token = getToken()

    if (!store.state.user.id) {
      store
        .dispatch('GetInfo')
        .then(() => {
          next()
        })
        .catch((e) => {
          store.dispatch('LogOut')
          reportError(e)
        })
    } else {
      next()
    }

  } else {
    if (
      whiteList.indexOf(to.path) !== -1 ||
      to.path.indexOf('/products') !== -1
    ) {
      next()
    } else {
      Vue.$loginBox.redirectTo(to.path)
      Vue.$loginBox.show()
    }
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
