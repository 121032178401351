import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/layout/Layout.vue'),
    redirect: '/home',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('../views/login/Login.vue'),
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/home/Index.vue'),
      },

      {
        path: 'my',
        name: 'my',
        component: () => import('../views/my/Index.vue'),
      },
      {
        path: 'my/help',
        name: 'help',
        component: () => import('../views/my/help/Index.vue'),
      },
      {
        path: 'my/help/list',
        name: 'help',
        component: () => import('../views/my/help/List.vue'),
      },
      {
        path: 'my/help/list/detail',
        name: 'help',
        component: () => import('../views/my/help/Detail.vue'),
      },
      {
        path: 'my/aboutus',
        name: 'aboutus',
        component: () => import('../views/my/AboutUs.vue'),
      },
      {
        path: 'tickets',
        name: 'tickets',
        component: () => import('../views/tickets/Index.vue'),
      },
      {
        path: 'purchase/results',
        name: 'purchase/results',
        component: () => import('../views/products/PaySuccess.vue'),
      },
      {
        path: '/pay-success',
        name: 'paySuccess',
        component: () => import('../views/products/PaySuccess.vue'),
      },
    ],
  },

  {
    path: '/products/:productId/detail',
    name: 'detail',
    component: () => import('../views/products/Detail.vue'),
  },
  {
    path: '/service-agreements',
    name: 'service',
    component: () => import('../views/agreements/Service.vue'),
  },
  {
    path: '/privacy-agreements',
    name: 'privacy',
    component: () => import('../views/agreements/Privacy.vue'),
  },
  {
    path: '/user-agreements',
    name: 'user',
    component: () => import('../views/agreements/User.vue'),
  },
  {
    path: '/user-info',
    name: 'user-info',
    component: () => import('../views/my/UserInfo.vue'),
  },
  {
    path: '/my/orders',
    name: 'orders',
    component: () => import('../views/my/Orders.vue'),
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('../views/my/AboutUs.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
