import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navText: '返回首页',
    selectedTabIdx: 0
  },
  getters,
  mutations: {
    SET_NAV: (state, nav) => {

      state.selectedTabIdx = nav.selectedTabIdx || 0

      if (nav.path == '/my') {
        state.navText = '返回首页'
      } else if (nav.path.includes('/home')) {
        state.navText = '个人中心'
      } else if (nav.path == '/my/orders') {
        state.navText = '个人中心'
      } else if (nav.path == '/login') {
        state.navText = '返回首页'
      }
    },
  },
  actions: {},
  modules: {
    user,
  },
})
