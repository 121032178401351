const TokenKey = 'Authorization-Token'
import { _axios } from '@/utils/axios'

const getToken = () => {
  return localStorage.getItem(TokenKey)
}

const setToken = (token) => {
  _axios.defaults.headers['Authorization'] = token
  return localStorage.setItem(TokenKey, token)
}

const removeToken = () => {
  delete _axios.defaults.headers['Authorization']
  return localStorage.removeItem(TokenKey)
}

export { getToken, setToken, removeToken }
