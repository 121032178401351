<template>
  <div class="login-mask" v-if="show">
    <div class="login-wrapper">
      <div class="login-box">
        <div class="login-header">手机号登录</div>

        <div class="login-body">

          <div class="register-tips">
            未注册过的手机号将自动创建账号
          </div>

          <div class="input-area">
            <input v-model="phoneNumber" placeholder="请输入手机号" />
            <div class="input-verification">
              <input type="number" v-model="verificationCode" placeholder="请输入验证码" />
              <div class="verification" :class="{
                disabled: !phoneNumberIsValid || showCodeLoading || codeCoolDown,
              }" @click="getPhoneCode">
                <div class="code-label">
                  {{ verificationCodeLabel }}
                </div>
                <div class="loading" v-if="showCodeLoading">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>

          <div class='agreements'>

            <div class="icon-wrapper" @click="agreementCheck = !agreementCheck">
              <svg v-if="agreementCheck" t="1679918602414" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="2759" width="0.3rem" height="0.3rem">
                <path
                  d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m-74.965333 550.4L346.453333 545.152a42.666667 42.666667 0 1 0-60.330666 60.330667l120.704 120.704a42.666667 42.666667 0 0 0 60.330666 0l301.653334-301.696a42.666667 42.666667 0 1 0-60.288-60.330667l-271.530667 271.488z"
                  fill="#52C41A" p-id="2760"></path>
              </svg>
              <div v-else class="check"></div>
            </div>

            <div style="margin-left: 0.12rem;">打勾表示您已阅读并同意以下协议<span style="color: #7CD000;cursor: pointer;"
                @click="nav('/user-agreements')">《拼咔用户服务协议》</span>以及<span style="color: #7CD000;cursor: pointer;"
                @click="nav('/privacy-agreements')">《拼咔隐私协议》</span></div>
          </div>
        </div>
        <div class="register-btn" :class="{
          disabled: !phoneNumberIsValid || verificationCode.length !== 6,
        }" @click="login">
          登录
        </div>

        <div class="close-btn-wrapper" @click="close">
          <div class="close-btn"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { getPhoneCode } from '@/api/user'
// import { reportError } from '@/utils/log'
import debounce from '@/utils/debounce'
import Vue from 'vue'
import store from '@/store'
import router from '@/router'

export default {
  name: 'login',
  props: {
    show: Boolean,
    redirectTo: String
  },
  data() {
    return {
      phoneNumber: '',
      showCodeLoading: false,
      codeCoolDown: false,
      btnDisable: false,
      verificationCodeLabel: '获取验证码',
      verificationCode: '',
      agreementCheck: false
    }
  },
  computed: {
    phoneNumberIsValid() {
      return /^1[3456789]\d{9}$/.test(this.phoneNumber)
    },
  },
  methods: {
    nav(path) {
      Vue.$loginBox.hide()
      router.push(path)
    },
    getPhoneCode: debounce(async function () {
      if (!this.showCodeLoading && !this.codeCoolDown) {
        if (!this.phoneNumberIsValid) {
          this.$toast.fail('请输入正确的手机号')
        } else {
          this.showCodeLoading = true
          let res = await getPhoneCode({
            phone: this.phoneNumber,
          })
          this.showCodeLoading = false

          if (res.data.code) {
            this.$toast.fail(res.data.message)
          }

          let countDown = 60
          this.codeCoolDown = true
          this.verificationCodeLabel = '重新获取 60 s'
          let interval = setInterval(() => {
            if (countDown <= 1) {
              this.codeCoolDown = false
              this.verificationCodeLabel = '重新获取'
              clearInterval(interval)
            } else {
              this.verificationCodeLabel = '重新获取 ' + --countDown + ' s'
            }
          }, 1000)
        }
      }
    }, 200),
    login: debounce(async function () {
      try {

        if (!this.phoneNumberIsValid) {
          this.$toast.fail('请输入正确的手机号')
          return
        }
        if (this.verificationCode.length !== 6) {
          this.$toast.fail('请正确输入验证码')
          return
        }

        if (!this.agreementCheck) {
          this.$toast.fail('请同意相关协议后继续哦')
          return
        }

        let res = await store.dispatch('Login', {
          phone: this.phoneNumber,
          code: this.verificationCode,
          openId: store.state.user.user.openId
        })

        if (res.code == 0) {
          Vue.$loginBox.hide()
          if (this.redirectTo) {
            router.push(this.redirectTo)
          }
        } else {
          this.$toast.fail(res.message)
        }
      } catch (error) {
        // reportError(error)
      }
    }, 200),
    close() {
      Vue.$loginBox.hide()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-mask {
  background: rgba(0, 0, 0, 0.70);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;

  .login-wrapper {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.30);

    .login-box {
      width: 7.5rem;
      background: linear-gradient(180deg, #EBFFC9 0%, #FFF 21.14%);
      height: 9.2rem;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      position: relative;
      border-radius: 0.2rem 0.2rem 0 0;

      .login-header {
        height: 1rem;
        color: #333;
        font-family: "Microsoft YaHei";
        font-size: 0.32rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .login-body {
        width: 6.86rem;

        margin-top: 0.12rem;

        .register-tips {
          color: rgba(153, 153, 153, 0.76);
          font-family: "PingFang SC";
          font-size: 0.24rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .input-area {
          input {
            width: 6.38rem;
            height: 0.84rem;
            font-size: 0.32rem;
            border: none;
            border-radius: 0.06rem;
            background: #F6F6F6;
            outline: none;

            padding: 0 0.24rem;

            &::placeholder {
              font-size: 0.28rem;
              color: #D1D1D1;
            }

            margin-bottom: 0.24rem;

            &:-webkit-autofill {
              -webkit-box-shadow: 0 0 0px 10rem white inset;
              -webkit-text-fill-color: #333;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none !important;
              margin: 0;
            }
          }

          .input-verification {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 1rem;
            width: 6.86rem;
            font-size: 0.32rem;
            margin-bottom: 0.2rem;

            input {
              width: 4.2rem;
              margin: 0;
              padding: 0 0.24rem;

              &::-webkit-inner-spin-button {
                -webkit-appearance: none !important;
              }
            }

            .verification {
              position: relative;
              color: #FFF;
              font-family: "PingFang SC";
              font-size: 0.28rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              cursor: pointer;
              height: 0.84rem;

              width: 2rem;

              border-radius: 0.06rem;
              background: #7CD000;

              &.disabled {
                background: rgba(124, 208, 0, 0.50);
              }


              .code-label.disabled {
                color: #FFF;
                opacity: 0.2;
                cursor: not-allowed;
                margin: 0;
              }

              display: flex;
              justify-content: center;
              align-items: center;

              .loading {
                position: absolute;
                top: 0.34rem;
                left: 0.9rem;
                width: 0.2rem;
                height: 0.2rem;

                span {
                  display: inline-block;
                  width: 0.04rem;
                  height: 0.04rem;
                  border-radius: 50%;
                  background: #7CD000;
                  position: absolute;
                  animation: load 1.04s ease infinite;

                  &:nth-child(1) {
                    left: 0;
                    top: 50%;
                    margin-top: -0.02rem;
                    animation-delay: 0.13s;
                  }

                  &:nth-child(2) {
                    left: 0.02rem;
                    top: 0.02rem;
                    animation-delay: 0.26s;
                  }

                  &:nth-child(3) {
                    left: 50%;
                    top: 0;
                    margin-left: -0.02rem;
                    animation-delay: 0.39s;
                  }

                  &:nth-child(4) {
                    top: 0.02rem;
                    right: 0.02rem;
                    animation-delay: 0.52s;
                  }

                  &:nth-child(5) {
                    right: 0;
                    top: 50%;
                    margin-top: -0.02rem;
                    animation-delay: 0.65s;
                  }

                  &:nth-child(6) {
                    right: 0.02rem;
                    bottom: 0.02rem;
                    animation-delay: 0.78s;
                  }

                  &:nth-child(7) {
                    bottom: 0;
                    left: 50%;
                    margin-left: -0.02rem;
                    animation-delay: 0.91s;
                  }

                  &:nth-child(8) {
                    bottom: 0.02rem;
                    left: 0.02rem;
                    animation-delay: 1.04s;
                  }
                }
              }
            }
          }
        }

        .agreements {


          .icon-wrapper {
            margin-top: 0.02rem;
            cursor: pointer;

            .check {
              width: 0.2rem;
              height: 0.2rem;
              border-radius: 50%;
              border: 0.02rem solid #969799;
              margin-top: 0.04rem;
              margin-right: 0.06rem;
            }
          }

          color: #999;
          font-family: "PingFang SC";
          font-size: 0.24rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          display: flex;
          text-align: left;


        }

      }

      .close-btn-wrapper {
        cursor: pointer;
        position: absolute;
        top: 0.32rem;
        right: 0.32rem;

        width: 0.56rem;
        height: 0.56rem;


        border-radius: 50%;

        background: #fff;

        .close-btn {
          position: relative;

          &::before,
          &::after {
            position: absolute;
            content: ' ';
            background-color: #333;
            left: 0.27rem;
            top: 0.12rem;
            width: 0.02rem;
            height: 0.3rem;
          }

          &::before {
            transform: rotate(45deg);
          }

          &::after {
            transform: rotate(-45deg);
          }
        }
      }

      .register-btn {
        position: absolute;
        bottom: 0.36rem;
        border-radius: 0.12rem;
        background: #333;
        width: 6.86rem;
        cursor: pointer;
        height: 1.04rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FFF;
        font-family: "PingFang SC";
        font-size: 0.32rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

}
</style>